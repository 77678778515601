<template>
  <div class="container-warp">
    <div class="top-box">
      <div>
        <back-title>课程详情</back-title>
        <div class="name-box">
          <com-title>课程名称111</com-title>
          <div class="name-box-r">
            <span>课程编号 HR001564</span>
            <span class="line-box"></span>
            <span>课程时长 共2节 | 2分钟</span>
          </div>
        </div>
      </div>
      <div class="video-box">
        <div class="video-box-l">
          <!-- <video
            ref="videoRef"
            width="1400px"
            src="@/assets/video/preview.mp4"
            controls
          ></video> -->
        </div>
        <div class="video-box-r">
          <div class="catalogue-box">
            <img src="@/assets/img/icon_catalogue.png" alt="" />
            <span>目录</span>
          </div>
          <div class="catalogue-box-item">
            <div class="catalogue-box-item-l">
              <i class="iconfont icon-icon_course"></i>课程目录名称
            </div>
            <div class="catalogue-box-item-r">1分32秒</div>
          </div>
          <div class="catalogue-box-item">
            <div class="catalogue-box-item-l">
              <i class="iconfont icon-icon_course_name"></i>课程目录名称
            </div>
            <div class="catalogue-box-item-r">pdf</div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-box" style="margin-top: 16px">
      <div style="margin-bottom:24px;">
        <com-title>课程描述</com-title>
        <div class="describe-text">
          计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述
        </div>
      </div>
      <div>
        <com-title>课程介绍内容</com-title>
        <div class="describe-text">
          课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import backTitle from '@/components/backTitle/index.vue'
import comTitle from '@/components/comTitle/index.vue'
export default {
  components: {
    backTitle,
    comTitle
  },
  created () {
    this.$nextTick(() => {
      this.$refs.videoRef.currentTime = 3
    })
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/iconfont/iconfont.css";
.top-box,
.bottom-box {
  padding: 16px 24px;
  background-color: #ffffff;
  border-radius: 4px;
}

/deep/.title-box {
  margin-bottom: 16px;
}
.name-box {
  display: flex;
  align-items: center;
  .name-box-r {
    display: flex;
    align-items: center;
    margin-left: 12px;
    font-size: 12px;
    color: #666666;
    .line-box {
      display: inline-block;
      margin: 0 12px;
      width: 1px;
      height: 16px;
      background-color: #dcdcdc;
    }
  }
}
.video-box {
  display: flex;

  margin-top: 16px;
  .video-box-r {
    width: 100%;
    .catalogue-box {
      display: flex;
      align-items: center;
      padding: 12px 24px;
      background: linear-gradient(180deg, #f5fbff 0%, #ffffff 100%);
      img {
        width: 30px;
        height: 30px;
      }
      span {
        font-size: 16px;
        font-weight: 500;
        color: #333333;
      }
    }
    .catalogue-box-item {
      padding: 7px 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #4d4d4d;
      .catalogue-box-item-l {
        display: flex;
        align-items: center;
        font-size: 14px;
        .icon-icon_course,
        .icon-icon_course_name {
          margin-right: 14px;
          font-size: 20px;
          color: #0089ff;
        }
      }
      .catalogue-box-item-r {
        font-size: 12px;
      }
    }
  }
}
.bottom-box {
  .describe-text {
    margin-top: 8px;
    font-size: 14px;
    color: #4d4d4d;
  }
}
</style>
